var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c("el-form", { attrs: { inline: true } }, [
        _c(
          "div",
          { staticClass: "row-bg" },
          [
            _c(
              "el-row",
              { attrs: { gutter: 24 } },
              [
                _c(
                  "el-col",
                  { attrs: { lg: 12, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "选择校区：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w100",
                            attrs: {
                              multiple: "",
                              filterable: "",
                              placeholder: "请选择",
                              clearable: "",
                              disabled: "",
                            },
                            model: {
                              value: _vm.formData.campusIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "campusIds", $$v)
                              },
                              expression: "formData.campusIds",
                            },
                          },
                          _vm._l(_vm.campusIdsList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.name, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { lg: 12, md: 24 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "过滤班级：" } },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "w100",
                            attrs: {
                              multiple: "",
                              filterable: "",
                              clearable: "",
                              disabled: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.formData.classIdsExclude,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "classIdsExclude", $$v)
                              },
                              expression: "formData.classIdsExclude",
                            },
                          },
                          _vm._l(_vm.classIdsExcludeList, function (item) {
                            return _c("el-option", {
                              key: item.class_id,
                              attrs: { label: item.name, value: item.class_id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.advanced ? void 0 : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-card",
        { attrs: { shadow: "never" } },
        [
          _c(
            "div",
            {
              staticClass: "flex",
              staticStyle: { "align-items": "center" },
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("div", { staticClass: "flex_1" }),
              _c(
                "download-excel",
                {
                  attrs: {
                    data: _vm.dataList,
                    fields: _vm.json_fields,
                    name: "教师课酬统计列表.xls",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { type: "primary", size: "mini" },
                    },
                    [_vm._v("导出报表")]
                  ),
                ],
                1
              ),
              _c("text-button", {
                attrs: { icon: "el-icon-refresh", contentTit: "刷新" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.search.apply(null, arguments)
                  },
                },
              }),
              _c("text-button", {
                attrs: {
                  icon: "el-icon-setting",
                  contentTit: "列设置",
                  colSetting: _vm.colSetting,
                  ispopover: true,
                  baseColSetting: _vm.baseColSetting,
                },
                on: { update: _vm.update },
              }),
            ],
            1
          ),
          _c(
            "el-table-bar",
            { attrs: { fixed: "", static: true } },
            [
              _c(
                "el-table",
                {
                  key: _vm.tableKey,
                  attrs: {
                    data: _vm.dataList,
                    fit: "",
                    "highlight-current-row": true,
                  },
                },
                _vm._l(_vm.defaultHeader, function (item) {
                  return _c("el-table-column", {
                    key: item.label,
                    attrs: {
                      label: item.label,
                      align: item.align ? item.align : "left",
                      "min-width": item.width ? item.width : "auto",
                      sortable: item.sortable ? item.sortable : false,
                      prop: item.prop,
                      "show-overflow-tooltip": true,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.label === "累计课时"
                                ? [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.changeHourMinutestr(
                                            scope.row.lessonLong
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                : item.label === "该教师总课时"
                                ? [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.changeHourMinutestr(
                                            scope.row.lessonLongTotal
                                          )
                                        ),
                                      },
                                    }),
                                  ]
                                : _c("span", [
                                    _vm._v(_vm._s(scope.row[item.prop])),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c("el-pagination", {
            staticClass: "fr-pagination",
            attrs: {
              background: "",
              "current-page": _vm.pagination.currentPage,
              "page-sizes": _vm.pagination.pageSizes,
              "page-size": _vm.pagination.pageSize,
              layout: _vm.pagination.layout,
              total: _vm.pagination.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }