import $axios from '@/utils/request'

// 查询全部校区
export function getCampusAll (data) {
  const url = '/campus/getCampusAll'
  return $axios.fPost(url, data)
}

// 新增校区
export function insertCampus (data) {
  const url = '/campus/insert'
  return $axios.fPost(url, data)
}

// 校区排序
export function sortCampus (data) {
  const url = '/campus/sortseq'
  return $axios.fPost(url, data)
}

// 编辑校区
export function editCampus (data) {
  const url = '/campus/edit'
  return $axios.fPost(url, data)
}

// 删除校区
export function delCampus (data) {
  const url = '/campus/delete'
  return $axios.fGet(url, data)
}

// 添加校区成员
export function insertCampusUser (data) {
  const url = '/campus/user/insert'
  return $axios.fPost(url, data)
}

// 删除校区成员
export function deleteCampusUser (data) {
  const url = '/campus/user/delete'
  return $axios.fPost(url, data)
}

// 根据校区获取用户列表
export function getUserByCampus (data) {
  const url = '/campus/user/getUserByCampus'
  return $axios.fPost(url, data)
}
