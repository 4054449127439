import $axios from '@/utils/request'

// 获取班容系数列表
export function getListLessonCoefficient (data) {
  const url = '/salary/listLessonCoefficient'
  return $axios.fGet(url, data)
}

// 更新班容系数
export function updateLessonCoefficient (data) {
  const url = '/salary/updateLessonCoefficient'
  return $axios.fPost(url, data)
}

// 获取试卷列表
export function getListSnapshot (data) {
  const url = '/salary/listSnapshot'
  return $axios.fPost(url, data)
}

// 生成薪酬快照
export function generateSnapshot (data) {
  const url = '/salary/generateSnapshot'
  return $axios.fGet(url, data)
}

// 获取薪酬统计列表
export function getSumSalary (data) {
  const url = '/salary/sumSalary'
  return $axios.fPost(url, data)
}

// 保存报表参数
export function saveSumSalaryParam (data) {
  const url = '/salary/saveSumSalaryParam'
  return $axios.fPost(url, data)
}

// 获取报表列表（历史查询保存过的报表）
export function getListSumSalaryParam (data) {
  const url = '/salary/listSumSalaryParam'
  return $axios.fPost(url, data)
}

// 更新快照薪酬
export function updateSnapshotSalary (data) {
  const url = '/salary/updateSnapshotSalary'
  return $axios.fGet(url, data)
}

// 获取课酬明细列表
export function getListSalary (data) {
  const url = '/salary/listSalary'
  return $axios.fPost(url, data)
}

// 获取按校区统计薪酬的结果列表
export function getSumSalaryByCampus (data) {
  const url = '/salary/sumSalaryByCampus'
  return $axios.fPost(url, data)
}

// 获取某校区教师课酬汇总的列表
export function getListSalaryForCampus (data) {
  const url = '/salary/listSalaryForCampus'
  return $axios.fPost(url, data)
}

// 统计薪酬
export function computeSalaryByCampus (data) {
  const url = '/salary/computeSalaryByCampus'
  return $axios.fPost(url, data)
}

// 检查可否进行统计
export function checkIntegrity (data) {
  const url = '/salary/checkIntegrity'
  return $axios.fGet(url, data)
}
